import React, { memo, useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import {IntlProvider} from 'react-intl'

import AppLocale from '../../lngProvider'
import MainApp from './MainApp'
import { useAppSelector } from '../../store/hooks'
import { selectLocaleSlice } from '../../store/LocaleSlice'
import { lightThemeToken, darkThemeToken } from '@vacationtracker/shared/constants/ThemeSetting'
import { selectThemeSlice } from '../../store/ThemeSlice'

import { availableLanguages } from '@vacationtracker/shared/i18n'

const App = (props) => {
  const { theme } = useAppSelector(selectThemeSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const safeLocale = locale?.locale || availableLanguages.en
  const [themeToken, setThemeToken] = useState((theme && ['dark', 'contrast'].includes(theme)) ? darkThemeToken : lightThemeToken)

  useEffect(() => {
    if (['dark', 'contrast'].includes(theme)) {
      setThemeToken(darkThemeToken)
    } else {
      setThemeToken(lightThemeToken)
    }
  }, [theme])

  const currentAppLocale = AppLocale[safeLocale]

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      theme={themeToken}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <MainApp {...props}/>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
